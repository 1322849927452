import { useHistory } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";

import { BusinessManagementInfo } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { THIS_YEAR_AS_TWO_DIGITS } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { SelectFilterOptionList } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

function ManagerSummary({
  adminUserId,
  businessManagementInfo,
  ForwardingManagerSelectFilterPanel,
  forwardingManagerSelectFilter,
}: {
  adminUserId: number;
  businessManagementInfo: BusinessManagementInfo;
  ForwardingManagerSelectFilterPanel: JSX.Element;
  forwardingManagerSelectFilter: SelectFilterOptionList<number> | undefined;
}) {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        p: 1,
        background: "#fff",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography>담당자명:</Typography>

        {ForwardingManagerSelectFilterPanel}
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>화주사(배정):</Typography>

          <Button
            onClick={() => {
              history.push(`/users/list`, {
                adminId: forwardingManagerSelectFilter?.value ?? adminUserId,
                userType: "consignee",
              });
            }}
            sx={{ minWidth: "auto", p: "4px" }}
            variant="text"
          >
            {businessManagementInfo.customerCnt}
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>진행중:</Typography>

          <Button
            onClick={() => {
              history.push(`/bid`, {
                adminId: forwardingManagerSelectFilter?.value ?? adminUserId,
                status: "inProgress",
              });
            }}
            sx={{ minWidth: "auto", p: "4px" }}
            variant="text"
          >
            {businessManagementInfo.proceedCnt}
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>진행건수:</Typography>

          <Typography>{businessManagementInfo.proceedBidCnt}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>{THIS_YEAR_AS_TWO_DIGITS}년도 누적 수주액:</Typography>

          <Typography>
            {toThousandUnitFormat(
              Math.floor(businessManagementInfo.yearSalesAmt)
            )}
            원
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>당월 수주액:</Typography>

          <Typography>
            {toThousandUnitFormat(
              Math.floor(businessManagementInfo.monthSalesAmt)
            )}
            원
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ManagerSummary;
