import { Divider } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/BUSINESS_MANAGEMENT_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSelectFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

import Loading from "components/Loading";

import BusinessManagementTable from "./_components/BusinessManagementTable";
import ManagerSummary from "./_components/ManagerSummary";

const BusinessOverview = () => {
  const { adminUserId } = ADMIN_USER_QUERY.useGetAdminUserList();

  const [businessManagementFilter, setBusinessManagementFilter] =
    useRecoilState(
      SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_BUSINESS_MANAGEMENT_FILTER_LIST
    );

  const handleForwardingManagerFilterHistoryChange = (value: number) => {
    setBusinessManagementFilter({
      ...businessManagementFilter,
      adminId: value,
    });
  };

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    defaultValue: businessManagementFilter?.adminId ?? adminUserId,
    selectFilterOptionList: [
      { label: "전체", value: 0 },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
    setHistoryData: handleForwardingManagerFilterHistoryChange,
  });

  const { data: businessManagementInfo } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementInfo({
      id: forwardingManagerSelectFilter?.value ?? adminUserId,
    });

  if (!businessManagementInfo) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout title="영업 현황">
      <ManagerSummary
        adminUserId={adminUserId}
        businessManagementInfo={businessManagementInfo}
        ForwardingManagerSelectFilterPanel={ForwardingManagerSelectFilterPanel}
        forwardingManagerSelectFilter={forwardingManagerSelectFilter}
      />

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <BusinessManagementTable
        adminId={forwardingManagerSelectFilter?.value ?? adminUserId}
      />
    </Layout>
  );
};

export default withRequireAuth(BusinessOverview);
